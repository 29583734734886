import Avatar from '@components/avatar'
// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = obj => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

// ** Converts HTML to string
export const htmlToString = html => html.replace(/<\/?[^>]+(>|$)/g, '')

export const isUserLoggedIn = () => localStorage.getItem('userData') && localStorage.getItem('userEmpresaToken')
export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

export const isClienteLoggedIn = () => localStorage.getItem('usuarioClienteData')

export const getClienteData = () => JSON.parse(localStorage.getItem('usuarioClienteData'))

export const getRutaCheck = () => sessionStorage.getItem('rutaCheck')

export const getToken = () => JSON.parse(localStorage.getItem('userEmpresaToken'))

export const getEleccionUsuario = (state) => state.EleccionUsuarioReducer.eleccionUsuario

export const prevRuta = (history, ruta) => {
  const url_string = location.href
  const url = new URL(url_string)
  const to = url.searchParams.get("to")
  if (to) {
    history.push(to)
  } else {
    history.push(ruta)
  }
}


export const RUBRO = [
  { value: 'Aseguradora', label: 'Aseguradora' },
  { value: 'Automotor', label: 'Automotor' },
  { value: 'Clubes', label: 'Clubes' },
  { value: 'Consorcios', label: 'Consorcios' },
  { value: 'Deportivo', label: 'Deportivo' },
  { value: 'Educativo', label: 'Educativo' },
  { value: 'Financiero', label: 'Financiero' },
  { value: 'Gimnasios', label: 'Gimnasios' },
  { value: 'Inmobiliario', label: 'Inmobiliario' },
  { value: 'Salud', label: 'Salud' },
  { value: 'otro', label: 'Otros rubros' }


]
/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === 'admin') return '/'
  if (userRole === 'client') return '/access-control'
  return '/login'
}

// ** React Select Theme Colors
export const selectThemeColors = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#7367f01a', // for option hover bg-color
    primary: '#7367f0', // for selected option bg-color
    neutral10: '#7367f0', // for tags bg-color
    neutral20: '#ededed', // for input border-color
    neutral30: '#ededed' // for input hover border-color
  }
})

export const RenderAvatar = ({ name, size, className, img, fontSize, onClick }) => {
  const handleClick = () => {
    if (onClick) {
      onClick()
    }
  }

  return (
    <div onClick={handleClick} className="cursor-pointer">
      {!img ? (
        <Avatar
          initials
          color="light-secondary"
          className={`my-20 ${className}`}
          content={name}
          contentStyles={{
            borderRadius: 0,
            fontSize: `calc(${fontSize}px)`,
            width: '100%',
            height: '100%'
          }}
          style={{
            height: `${size}px`,
            width: `${size}px`
          }}
        />
      ) : (
        <img
          className="user-avatar rounded mr-2 my-25"
          src={img}
          alt={name || 'user profile avatar'}
          height={size}
          width={size}
          style={{ cursor: 'pointer', zIndex: '1' }}
        />
      )}
    </div>
  )
}

export const RenderAvatarClient = ({ img, size, onClick }) => {
  return (
      <img
          alt="..."
          onClick={onClick}
          className="rounded-circle cursor-pointer border shadow-img"
          src={img || false}
          style={{ height: `${size}px`, width: `${size}px`, zIndex: '1', background: "white" }}
      />
  )
}

export const handleFrecuencia = (frecuencia) => {
  switch (frecuencia) {
      case "Semanal":
          return "semana"
          break
      case "Quincenal":
          return "quince dias"
          break
      case "Mensual":
          return "mes"
          break
      case "Bimestral":
          return "dos meses"
          break
      case "Trimestral":
          return "tres meses"
          break
      case "Cuatrimestral":
          return "cuatro meses"
          break
      case "Semestral":
          return "seis meses"
          break
      case "Anual":
          return "año"
          break
      default:
          break
  }
}

export const formatDate = (date) => date.replace(/^(\d{4})-(\d{2})-(\d{2})$/g, '$3/$2/$1')

export const toBoolean = (a) => a.length >= 1