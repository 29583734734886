import { BACKEND } from "@src/BACKEND"
import axios from "axios"
import { getToken } from '@utils'
import FetchRefreshToken from '@refreshToken'

export const GET_VALIDACION_REQUEST = 'GET_VALIDACION_REQUEST'
export const GET_VALIDACION_SUCCESS = 'GET_VALIDACION_SUCCESS'
export const GET_VALIDACION_ERROR = 'GET_VALIDACION_ERROR'

const GetValidacionRequest = () => {
    return {
        type: GET_VALIDACION_REQUEST
    }
}

const GetValidacionSuccess = (img) => {
    return {
        type: GET_VALIDACION_SUCCESS,
        payload: img
    }
}

const GetValidacionError = (error) => {
    return {
        type: GET_VALIDACION_ERROR,
        payload: error
    }
}

const GetValidacion = (idEmpresa) => {
    return (dispatch) => {
        dispatch(GetValidacionRequest())
        axios({
            method: 'GET',
            headers: { Authorization: `Bearer ${getToken()}` },
            url: `${BACKEND}checkout/imagenesValidacionEmpresa`,
            params: { id_empresa: idEmpresa }
        })
        .then((res) => {
            dispatch(GetValidacionSuccess(res.data.data))
        })
        .catch((error) => {
            dispatch(GetValidacionError(error.response))
        })

    }
}

export default GetValidacion
