import axios from 'axios'
import { BACKEND } from '@src/BACKEND'
import { notifySuccessProgress } from '@src/components/Toast/successToast'
import { getToken } from '@utils'
import FetchRefreshToken from '@refreshToken'

export const UPLOAD_SELFIE_REQUEST = 'UPLOAD_SELFIE_REQUEST'
export const UPLOAD_SELFIE_SUCCESS = 'UPLOAD_SELFIE_SUCCESS'
export const UPLOAD_SELFIE_FAILURE = 'UPLOAD_SELFIE_FAILURE'

export const UploadSelfieRequest = () => {
    return {
        type: UPLOAD_SELFIE_REQUEST
    }
}

export const UploadSelfieSuccess = (selfie) => {
    return {
        type: UPLOAD_SELFIE_SUCCESS,
        payload: selfie
    }

}

export const UploadSelfieFailure = (error) => {
    return {
        type: UPLOAD_SELFIE_FAILURE,
        payload: error
    }
}

const UploadSelfieAction = (imgSrc, idEmpresa, setLoadPic) => {
    return (dispatch) => {
        const formData = new FormData()
        formData.append('selfie', imgSrc)
        formData.append('id_usuario_empresa', idEmpresa)
        dispatch(UploadSelfieRequest())
        axios({
            method: 'post',
            headers: { Authorization: `Bearer ${getToken()}` },
            url: `${BACKEND}checkout/uploadSelfieEmpresa`,
            data: formData
        })
            .then(res => {
                dispatch(UploadSelfieSuccess(res))
                if (res.status === 200) {
                    setLoadPic(true)
                    notifySuccessProgress(
                        res.data.message
                    )
                }
            })
            .catch(error => {
                dispatch(UploadSelfieFailure(error.response))

                dispatch(FetchRefreshToken(
                    {
                        status: error.response?.status,
                        fnc: UploadSelfieAction(imgSrc, idEmpresa, setLoadPic)
                    }
                ))
            })
    }
}

export default UploadSelfieAction