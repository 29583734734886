import {
    BACKEND
} from "@src/BACKEND"
import axios from "axios"
import { getToken } from '@utils'
import FetchRefreshToken from '@refreshToken'


export const GET_IMAGENES_VALIDACION_REQUEST = 'GET_IMAGENES_VALIDACION_REQUEST'
export const GET_IMAGENES_VALIDACION_SUCCESS = 'GET_IMAGENES_VALIDACION_SUCCESS'
export const GET_IMAGENES_VALIDACION_ERROR = 'GET_IMAGENES_VALIDACION_ERROR'

const GetDniRequest = () => {
    return {
        type: GET_IMAGENES_VALIDACION_REQUEST
    }
}

const GetDniSuccess = (imagenes) => {
    return {
        type: GET_IMAGENES_VALIDACION_SUCCESS,
        payload: imagenes
    }
}

const GetDniError = (error) => {
    return {
        type: GET_IMAGENES_VALIDACION_ERROR,
        payload: error
    }
}

const getDniAction = (idEmpresa, setSelfie, setDniFrontal, setDniDorso) => {
    return (dispatch) => {
        dispatch(GetDniRequest())
        axios({
            method: 'GET',
            headers: { Authorization: `Bearer ${getToken()}` },
            url: `${BACKEND}checkout/imagenesValidacionEmpresa?id_empresa=${idEmpresa}`
        })
            .then((res) => {
                dispatch(GetDniSuccess(res))
                const imgs = res.data
                const { selfie, dni_delantero, dni_trasero } = imgs.data
                if (selfie !== null) setSelfie(true)
                if (dni_delantero !== null) setDniFrontal(true)
                if (dni_trasero !== null) setDniDorso(true)
            })
            .catch((error) => {
                dispatch(GetDniError(error.response))

                dispatch(FetchRefreshToken(
                    {
                        status: error.response?.status,
                        fnc: getDniAction(idEmpresa, setSelfie, setDniFrontal, setDniDorso)
                    }
                ))
            })
    }
}

export default getDniAction
