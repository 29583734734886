import { lazy } from 'react'

const PageRoutes = [
    {
        path: '/inicio',
        component: lazy(() => import('@src/pages/home/Home'))
    },
    {
        path: '/inicio-cliente',
        component: lazy(() => import('@src/pages/home-clientes/Inicio.js'))
    },
    {
        path: '/clientes',
        component: lazy(() => import('@src/pages/clientes/Clientes')),
        exact: true
    },
    {
        path: '/planes/:id',
        component: lazy(() => import('@src/pages/planes')),
        exact: true
    },
    {
        path: '/planes',
        component: lazy(() => import('@src/pages/planes')),
        exact: true
    },
    {
        path: '/actividad',
        component: lazy(() => import('@src/pages/actividad/Actividad'))
    },
    {
        path: '/suscripciones-cliente',
        component: lazy(() => import('@src/pages/Suscripciones-cliente/suscripciones'))
    },
    {
        path: '/cliente-ayuda',
        component: lazy(() => import('@src/pages/ayuda-cliente/AyudaCliente'))
    },
    {
        path: '/cliente-pagos',
        component: lazy(() => import('@src/pages/ayuda-cliente/pagos-cliente/pagos'))
    },
    {
        path: '/cliente-seguridad',
        component: lazy(() => import('@src/pages/ayuda-cliente/seguridad-cliente/seguridad'))
    },
    {
        path: '/detalle-suscripcion-cliente/:id',
        component: lazy(() => import('@src/pages/Suscripciones-cliente/detalle-suscripcion/detalleSuscripcion'))
    },
    {
        path: '/perfil',
        component: lazy(() => import('@src/pages/perfil/Perfil'))
    },
    {
        path: '/perfil-cliente',
        component: lazy(() => import('@src/pages/Perfil-cliente/perfilCliente'))
    },
    {
        path: '/medios-cliente',
        component: lazy(() => import('@src/pages/medio-pago-cliente/metodosPagos'))
    },
    {
        path: '/medios/agregar',
        component: lazy(() => import('@src/pages/medio-pago-cliente/agregarMetodoPago.js'))
    },
    {
        path: '/reportes',
        component: lazy(() => import('@src/pages/reportes'))
    },
    {
        path: '/vender',
        component: lazy(() => import('@src/pages/vender/Vender'))
    },
    {
        path: '/agregarQr',
        component: lazy(() => import('../../pages/options/qr/agregarQr'))
    },

    {
        path: '/metricas',
        component: lazy(() => import('@src/pages/metricas/Metricas'))
    },
    {
        path: '/ayuda',
        component: lazy(() => import('@src/pages/faq2'))
    },
    {
        path: '/ayuda-cobros',
        component: lazy(() => import('@src/pages/faq/cobros/Cobros.js'))
    },
    {
        path: '/ayuda-plan',
        component: lazy(() => import('@src/pages/faq/plan/Plan.js'))
    },
    {
        path: '/ayuda-pagos',
        component: lazy(() => import('@src/pages/faq/pagos/pagos.js'))
    },
    {
        path: '/ayuda-seguridad',
        component: lazy(() => import('@src/pages/faq/seguridad/seguridad.js'))
    },
    {
        path: '/respuesta/:id',
        component: lazy(() => import('@src/pages/faq2/detailFaq'))
    },
    {
        path: '/dinero',
        component: lazy(() => import('@src/pages/dinero/Dinero'))
    },
    {
        path: '/introduccion',
        component: lazy(() => import('@src/pages/onboarding/onboarding')),
        layout: 'BlankLayout',
        meta: {
            authRoute: true
        }
    },
    {
        path: '/elegir-rol',
        component: lazy(() => import('@src/pages/roles/roles')),
        layout: 'BlankLayout',
        meta: {
            authRoute: true
        }
    },
    {
        path: '/cuentas',
        component: lazy(() => import('@src/pages/seleccionarCuenta/seleccionarCuenta')),
        layout: 'BlankLayout',
        meta: {
            authRoute: true
        }
    },
    {
        path: '/iniciar-sesion',
        component: lazy(() => import('@src/pages/authentication/login/Login')),
        layout: 'BlankLayout',
        exact: true,
        meta: {
            authRoute: true
        }
    },
    {
        path: '/registro',
        component: lazy(() => import('@src/pages/authentication/register/Register')),
        layout: 'BlankLayout',
        meta: {
            authRoute: true
        }
    },
    {
        path: '/recuperar-contrasena',
        component: lazy(() => import('@src/pages/authentication/forgotPassword/ForgotPassword')),
        layout: 'BlankLayout',
        meta: {
            authRoute: true
        }
    },
    {
        path: '/cambiar-contrasena/:id',
        component: lazy(() => import('@src/pages/authentication/resetPassword/ResetPassword')),
        layout: 'BlankLayout',
        meta: {
            authRoute: true
        }
    },
    {
        path: '/error',
        component: lazy(() => import('@src/pages/404/Error')),
        layout: 'BlankLayout'
    },
    {
        path: '/no-autorizado',
        component: lazy(() => import('@src/pages/NotAuthorized/NotAuthorized')),
        layout: 'BlankLayout'
    },
    {
        path: '/validacion',
        component: lazy(() => import('@src/pages/validacion/validacion')),
        layout: 'BlankLayout',
        meta: {
            authRoute: true
        }
    },
    {
        path: '/checkout/:IDRECORRIDO',
        component: lazy(() => import('@src/pages/checkout/Checkout')),
        exact: true,
        layout: 'BlankLayout',
        meta: {
            authRoute: true,
            hideMenu: true
        }
    }
]

export default PageRoutes