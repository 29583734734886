// ** Dropdowns Imports
import { Fragment, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { ThemeToggler } from './ThemeToggler'
import UserDropdown from './UserDropdown'

// ** Third Party Components
import { Sun, Moon, Menu } from 'react-feather'
import { NavItem, NavLink } from 'reactstrap'
import NavbarSearch from './NavbarSearch'
import FetchGetEleccionUsuario from '../../../../redux/actions/eleccion-usuario/eleccionUsuarioAction'
import PutEleccionUsuarioAction from '../../../../redux/actions/eleccion-usuario/updateEleccionUsuarioAction'

const NavbarUser = props => {
  const { skin, setSkin, setMenuVisibility } = props
  const eleccionUsuario = useSelector(state => state.EleccionUsuarioReducer.eleccionUsuario)
  const [userCliente] = useState(JSON.parse(localStorage.getItem('userCliente')))
  const dispatch = useDispatch()

  const handleEleccionCliente = async (eleccion) => {
    try {
      await dispatch(PutEleccionUsuarioAction(userCliente.id_usuario, eleccion))
      await dispatch(FetchGetEleccionUsuario(userCliente.id_usuario))
    } catch (error) {
      console.log(error, "error")
    }
  }

  useEffect(() => {
    if (userCliente?.id_usuario) {
      dispatch(FetchGetEleccionUsuario(userCliente.id_usuario))
    }
  }, [dispatch, userCliente])

  return (
    <Fragment>
      <ul className='navbar-nav d-xl-none d-flex align-items-center'>
        <NavItem className='mobile-menu mr-auto'>
          <NavLink className='nav-menu-main menu-toggle hidden-xs is-active' onClick={() => setMenuVisibility(true)}>
            <Menu className='ficon' />
          </NavLink>
        </NavItem>
      </ul>
      <div className='bookmark-wrapper d-flex align-items-center container-fluid'>
        <NavbarSearch />
      </div>
      <div className='d-flex align-items-center justify-content-end container-fluid'>
        <div className=''>
          <NavItem className='d-none d-lg-block'>
            <NavLink className='nav-link-style'>
              <ThemeToggler
                skin={skin}
                setSkin={setSkin}
              />
            </NavLink>
          </NavItem>
        </div>
        <ul className='nav navbar-nav align-items-center'>
          <UserDropdown eleccionUsuario={eleccionUsuario} handleEleccionCliente={handleEleccionCliente} />
        </ul>
      </div>
    </Fragment>
  )
}
export default NavbarUser
